<template>
    <!-- 轨迹查询 -->
    <div class="qa">
        <div class="computer">
            <div class="tracking">
                <h1 class="tracking_title">{{getLanguageText(languageType, '物流查询')}}</h1>
                <el-form ref="dataForm" :rules="rules" :model="dataForm" class="tracking_form">
                    <el-row>
                        <el-col :span="18">
                            <el-form-item>
                                <el-input class="tracking_input" v-model="dataForm.queryCodes" clearable :placeholder="getLanguageText(languageType, '请输入单号')" style=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                        <el-form-item>
                            <div class="tracking_but">
                                <div class="tracking_but_item color_fff bg_olor_003a9b" @click="getInquire()"><i class="el-icon-search mr10"></i>{{getLanguageText(languageType, '查询')}}</div>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div v-if="dataList.length" class="mb10">
                <span :class="['bgc_trackStatus',`bgc_${dataList[0].ctrackStatus}`]" class="tag">{{dataList[0].ctrackStatus | labelMapper(trackStatusList, ['name', 'value'])}}</span>
            </div>
            <el-collapse v-show="dataList.length" v-loading="dataListLoading" v-model="collapseArr" @change="collapseChange" style="width: 100%">
                <el-collapse-item v-for="(item, index) in dataList" :key="index" :name="index" class="collapse_item_none">
                    <template slot="title">
                        <el-row :class="`color_${item.ctrackStatus}`" style="width: 100%">
                            <el-col :span="8">
                                <p>{{getLanguageText(languageType, '单号')}}: {{item.serviceBillNo}}</p>
                            </el-col>
                            <el-col :span="5" class="div_ellipsis">
                                {{getLanguageText(languageType, '轨迹发生地点')}}: {{item.ctrackNewArea}}
                            </el-col>
                            <el-col :span="10" class="div_ellipsis">
                                {{item.ctrackNewTime}} {{item.ctrackNewComment}}
                            </el-col>
                        </el-row>
                    </template>
                    <el-timeline v-loading="item.loading" class="p10">
                        <el-timeline-item v-for="(activity, i) in item.details" :key="i">
                            <span class="mr20" style="font-weight: 700;">{{activity.ctrackTime}}</span>
                            <span>{{activity.ctrackComment}}</span>
                        </el-timeline-item>
                    </el-timeline>
                </el-collapse-item>
            </el-collapse>
            <div class="empty">
                <el-empty v-show="!dataList.length" v-loading="dataListLoading" :description="descriptionText" class="color_r"></el-empty>
            </div>
        </div>
        <div class="phone">
            <div class="tracking">
                <h1 class="tracking_title">物流查询</h1>
                <el-form ref="dataForm" :rules="rules" :model="dataForm" class="tracking_form">
                    <el-row style="height: 18px">
                        <el-col :span="18">
                            <input v-model="dataForm.queryCodes" clearable placeholder="请输入单号" style="border: 1px solid #ccc;border-radius: 5px;padding-left: 5px;"/>
                        </el-col>
                        <el-col :span="6">
                        <el-form-item>
                            <div style="height: 18px;line-height: 18px;border-radius: 5px;" class="color_fff bg_olor_003a9b" @click="getInquire()">
                                <h1 class=""><i class="el-icon-search mr10"></i>查询</h1>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div v-if="dataList.length" class="mb10">
                <span :class="['bgc_trackStatus',`bgc_${dataList[0].ctrackStatus}`]" class="tag" style="height: 20px;line-height: 20px;">{{dataList[0].ctrackStatus | labelMapper(trackStatusList, ['name', 'value'])}}</span>
            </div>
            <el-collapse v-show="dataList.length" v-loading="dataListLoading" v-model="collapseArr" @change="collapseChange" style="width: 100%">
                <el-collapse-item v-for="(item, index) in dataList" :key="index" :name="index" class="collapse_item_none">
                    <template slot="title">
                        <el-row :class="`color_${item.ctrackStatus}`" style="width: 100%">
                            <el-col :span="24">
                                <p>单号: {{item.serviceBillNo}}</p>
                            </el-col>
                        </el-row>
                    </template>
                    <el-timeline v-loading="item.loading" class="p10">
                        <el-timeline-item v-for="(activity, i) in item.details" :key="i">
                            <span class="mr10">{{activity.ctrackTime}}</span>
                            <span>{{activity.ctrackComment}}</span>
                        </el-timeline-item>
                    </el-timeline>
                </el-collapse-item>
            </el-collapse>
            <div class="empty">
                <el-empty v-show="!dataList.length" v-loading="dataListLoading" :description="descriptionText" class="color_r"></el-empty>
            </div>
        </div>
        <phone-menu></phone-menu>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import phoneMenu from '../../components/phoneMenu.vue'
import axios from 'axios'
import { trackGet } from '@/api/index'
export default {
    data() {
        return {
            dataList: [],
            dataListLoading: false,
            trackStatusList: [  // 轨迹状态
                { value: 'PRE', name: '预报' },
                { value: 'DIN', name: '已收货' },
                { value: 'OUT', name: '已发货' },
                { value: 'ONNET', name: '上网' },
                { value: 'TRANSIT', name: '运输途中' },
                { value: 'DEPARTAIRPORT', name: '离开机场' },
                { value: 'ARRIVEAIRPORT', name: '到达机场' },
                { value: 'DEPARTCUSTOMS', name: '离开海关' },
                { value: 'OUTDELIVERY', name: '开始派送' },
                { value: 'BACK', name: '退回发件' },
                { value: 'DELIVERED', name: '成功签收' },
            ],
            collapseArr: [0],
            rules: {
                question: [
                    { required: true, message: '请输入单号', trigger: 'blur' }
                ],
            },
            ifQuiz: false,
            dataForm: {},
            descriptionText: ''
        }
    },
    components: {
        phoneMenu
    },
    computed: {
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
        trackNum(){
            return this.$store.state.trackNum
        },
    },
    mounted() {
        let maxResult = window.matchMedia('(max-width: 980px)')
        if (maxResult.matches) {
            var h =window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
            let he = document.getElementsByClassName('p_header')[0].offsetHeight
            document.getElementsByClassName('qa')[0].style.minHeight = (h - h - he - 2) + 'px'
        }
        if(this.trackNum) {
            let num = JSON.parse(JSON.stringify(this.trackNum))
            this.$set(this.dataForm, 'queryCodes', num)
            this.getInquire()
            this.$store.commit('getTrackNum', '')
        }
        this.descriptionText = this.getLanguageText(this.languageType, '暂无数据')
    },
    methods: {
        getInquire() {
            if(this.dataForm.queryCodes) {
                this.dataListLoading = true
                let data = {
                    serviceBillNo: this.dataForm.queryCodes
                }
                trackGet(data).then((res) => {
                    if(res && res.code == 0) {
                        this.dataListLoading = false
                        let ress = res.data
                        if(ress.ctrackStatus) {
                            this.dataList = [ ress ]
                        } else {
                            this.dataList = []
                            this.descriptionText = this.getLanguageText(this.languageType, '暂无轨迹数据可联系小派查询')
                        }
                    }
                }).catch(err => {
                    this.dataListLoading = false
                    this.dataList = []
                    this.descriptionText = this.getLanguageText(this.languageType, '暂无轨迹数据可联系小派查询')
                })
                /* axios({
                      url: '//toms.parceljet.com/psj-cloud-web/api/method=com.psj.biz.track.get',
                      method: 'post',
                      data: data
                    }).then((res) => {
                        this.dataListLoading = false
                        let ress = res.data
                        if(ress.data.ctrackStatus) {
                            this.dataList = [ ress.data ]
                        } else {
                            this.dataList = []
                            this.descriptionText = this.getLanguageText(this.languageType, '暂无轨迹数据可联系小派查询')
                        }
                    }).catch(err => {
                        this.dataListLoading = false
                        this.dataList = []
                        this.descriptionText = this.getLanguageText(this.languageType, '暂无轨迹数据可联系小派查询')
                    }) */
            } else {
                this.dataList = []
                Message.error({ message: this.getLanguageText(this.languageType, '请输入单号')})
            }
        },
        // 轨迹状态切换
        getOrderStatus(type) {
            this.dataForm.orderStatus = type;
            // this.getDataList()
        },
        collapseChange() {
            this.collapseArr = [0]
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 980px) {
    .qa {
        width: 90vw !important;
        .label {
            font-size: 2.5vw !important;
        }
        .content {
            font-size: 2.5vw !important;
        }
    }
    .bt_r {
        font-size: 2.5vw;
    }
    .el-textarea {
        font-size: 2.5vw;
    }
}
.qa {
    width: 1300px;
    padding: 20px 0 50px;
    margin: 0 auto;
    .tracking {
        text-align: center;
        .tracking_title {
            font-weight: 700;
            -webkit-background-clip: text;
            background-clip: text;
            color: #003a9b;
            font-size: 40px;
            margin-bottom: 20px;
        }
        .tracking_form {
            width: 70%;
            margin: 0 auto;
            .tracking_input {
                // height: 120px !important;
                /deep/ .el-textarea__inner {
                    // height: 120px !important;
                    font-size: 16px !important;
                }
            }
            .tracking_but {
                height: 100px;
                // display: flex;
                padding-left: 20px;
                flex-direction: column;
                justify-content: space-around;
                font-size: 18px;
                .tracking_but_item {
                    height: 45%;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    line-height: 50px;
                    cursor: pointer;
                }
            }
        }
    }
    .mr10 {
        margin-right: 10px;
    }
    .color_fff {
        color: #fff;
    }
    .bg_olor_003a9b {
        background-color: #003a9b;
    }
    // 预报
    .bgc_PRE {
        background-color: #909399;
        border-color: #909399;
        color: #fff;
    }
    .color_PRE {
        color: #909399;
    }
    // 签入
    .bgc_DIN {
        background-color: #409EFF;
        border-color: #409EFF;
        color: #fff;
    }
    .color_DIN {
        color: #409EFF;
    }
    // 签出
    .bgc_OUT {
        background-color: #6cb3fa;
        border-color: #6cb3fa;
        color: #fff;
    }
    .color_OUT {
        color: #6cb3fa;
    }
    // 上网
    .bgc_ONNET {
        background-color: #E6A23C;
        border-color: #E6A23C;
        color: #fff;
    }
    .color_ONNET {
        color: #E6A23C;
    }
    // 退回发件
    .bgc_BACK {
        background-color: red;
        border-color: red;
        color: #fff;
    }
    .color_BACK {
        color: red;
    }
    // 运输途中
    .bgc_TRANSIT {
        background-color: #0D63EE;
        border-color: #0D63EE;
        color: #fff;
    }
    .color_TRANSIT {
        color: #0D63EE;
    }
    // 成功签收
    .bgc_DELIVERED {
        background-color: #67C23A;
        border-color: #67C23A;
        color: #fff;
    }
    .color_DELIVERED {
        color: #67C23A;
    }
    // 离开机场
    .bgc_DEPARTAIRPORT {
        background-color: #8ee474;
        border-color: #8ee474;
        color: #fff;
    }
    .color_DEPARTAIRPORT {
        color: #8ee474;
    }
    // 到达机场
    .bgc_ARRIVEAIRPORT {
        background-color: #56e987;
        border-color: #56e987;
        color: #fff;
    }
    .color_ARRIVEAIRPORT {
        color: #56e987;
    }
    // 离开海关
    .bgc_DEPARTCUSTOMS {
        background-color: #78ace9;
        border-color: #78ace9;
        color: #fff;
    }
    .color_DEPARTCUSTOMS {
        color: #78ace9;
    }

    .line {
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
        background-color: #5067A1;
    }
    .asking {
        width: 100%;
        min-height: 40px;
        padding: 0 20px;
        box-sizing: border-box;
        line-height: 40px;
        border: 1px solid #ccc;
        border-bottom: 0;
    }
    .answer {
        width: 100%;
        min-height: 40px;
        padding: 0 20px;
        box-sizing: border-box;
        line-height: 1.5;
        border: 1px solid #ccc;
    }
    .label {
        margin-right: 15px;
        font-size: 40px;
        color: black;
        font-weight: 400;
        font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    }
    .content {
        font-size: 20px;
        color: rgb(88, 88, 88);
        font-weight: 600;
        font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    }
}
.color_r {
    color: red;
}
.phone {
    /deep/ .el-empty__description {
        p {
            font-size: 2vw;
        }
    }
}
.empty {
    width: 100%;
    display: flex;
    justify-content: center;
}
.div_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p10 {
    padding: 10px
}
.mr10 {
    margin-right: 10px;
}
.mr20 {
    margin-right: 20px;
}
.mb10 {
    margin-bottom: 10px;
}
.bgc_trackStatus {
    background-color: #8ab3f5;
    border-color: #8ab3f5;
}
// 预报
.bgc_PRE {
  background-color: #909399;
  border-color: #909399;
}
.color_PRE {
  color: #909399;
}
// 签入
.bgc_DIN {
  background-color: #409EFF;
  border-color: #409EFF;
}
.color_DIN {
  color: #409EFF;
}
// 签出
.bgc_OUT {
  background-color: #6cb3fa;
  border-color: #6cb3fa;
}
.color_OUT {
  color: #6cb3fa;
}
// 上网
.bgc_ONNET {
  background-color: #E6A23C;
  border-color: #E6A23C;
}
.color_ONNET {
  color: #E6A23C;
}
// 退回发件
.bgc_BACK {
  background-color: red;
  border-color: red;
}
.color_BACK {
  color: red;
}
// 运输途中
.bgc_TRANSIT {
  background-color: #0D63EE;
  border-color: #0D63EE;
}
.color_TRANSIT {
  color: #0D63EE;
}
// 成功签收
.bgc_DELIVERED {
  background-color: #67C23A;
  border-color: #67C23A;
}
.color_DELIVERED {
  color: #67C23A;
}
// 离开机场
.bgc_DEPARTAIRPORT {
  background-color: #8ee474;
  border-color: #8ee474;
  color: #fff;
}
.color_DEPARTAIRPORT {
  color: #8ee474;
}
// 到达机场
.bgc_ARRIVEAIRPORT {
  background-color: #56e987;
  border-color: #56e987;
  color: #fff;
}
.color_ARRIVEAIRPORT {
  color: #56e987;
}
// 离开海关
.bgc_DEPARTCUSTOMS {
  background-color: #78ace9;
  border-color: #78ace9;
  color: #fff;
}
.color_DEPARTCUSTOMS {
  color: #78ace9;
}

.tag {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
  color: #fff;
}
.collapse_item_none {
    /deep/.el-collapse-item__arrow {
        display: none;
    }
}
</style>