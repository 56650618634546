<template>
    <!-- 发货证明 -->
    <div class="qa">
        <div class="computer">
            <div class="tracking">
                <h1 class="tracking_title">{{getLanguageText(languageType, '发货证明')}}</h1>
                <el-form ref="dataForm" :rules="rules" :model="dataForm" class="tracking_form">
                    <el-row>
                        <el-col :span="18">
                            <el-form-item>
                                <el-input class="tracking_input order" v-model="dataForm.queryCodes" clearable :disabled="ifDisabled" :placeholder="getLanguageText(languageType, '请输入单号')">
                                    <template slot="append"><div @click="getTextarea()" style="font-size: 18px;"><i class="el-icon-zoom-in"></i></div></template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <div class="tracking_but">
                                    <div class="tracking_but_item color_fff bg_olor_003a9b" @click="getInquire()"><i class="el-icon-search mr10"></i>{{getLanguageText(languageType, '查询')}}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="text-align: right;" class="mb10">
                <el-button type="primary" size="small" :disabled="dataListSelections.length <= 0" @click="downloadLabe({}, 2)">{{getLanguageText(languageType, '批量下载')}}</el-button>
            </div>
            <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChange" style="width: 100%;" max-height="400px">
                <el-table-column type="selection" :selectable="checkSelectable" header-align="center" align="center" width="50"></el-table-column>
                <el-table-column type="index" header-align="center" align="center" label="序号" width="50"></el-table-column>
                <el-table-column prop="order" header-align="center" align="center" label="单号"></el-table-column>
                <el-table-column prop="status" header-align="center" align="center" label="发货证明"></el-table-column>
                <el-table-column fixed="right" header-align="center" align="center" width="120" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == '已成功获取'" type="text" size="small" @click="downloadLabe(scope.row, 1)">{{getLanguageText(languageType, '下载')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="phone">
            {{getLanguageText(languageType, '请前往电脑端操作下载')}}
        </div>
        <!-- 快速导入单号 -->
        <lead-dialog :textareaDialog="textareaDialog" @quickCancel="quickCancel" @confirm="confirm" :textarea2="textarea2"></lead-dialog>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import { fhzmGet } from '@/api/index'
import leadDialog from '../components/leadDialog.vue' // 快速导入单号模态框
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
    data() {
        return {
            dataForm: {
                queryCodes: '',
            },
            dataListSelections: [],
            dataList: [],
            dataListLoading: false,
            rules: {
                question: [
                    { required: true, message: '请输入单号', trigger: 'blur' }
                ],
            },
            /* 快速导入单号模态框 */
            textareaDialog: false, // 快速导入模态框显示与否
            ifDisabled: false,  // 输入框是否禁用
            textarea2: '',
        }
    },
    components: {
        leadDialog
    },
    computed: {
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
    },
    mounted() {
        let maxResult = window.matchMedia('(max-width: 980px)')
        if (maxResult.matches) {
            var h =window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
            let he = document.getElementsByClassName('p_header')[0].offsetHeight
            document.getElementsByClassName('qa')[0].style.minHeight = (h - h - he - 2) + 'px'
        }
    },
    methods: {
        /* 快速导入订单 */
        // 关闭快速导入订单模态框
        // 打开快速导入订单模态框
        getTextarea() {
            this.textareaDialog = true
            let regex = /,/g;
            this.textarea2 = this.dataForm.queryCodes.replace(regex, '\n') || ''
        },
        quickCancel() {
            this.textareaDialog = false;
        },
        // 快速导入订单模态框确定
        confirm(data) {
            if(data.length > 0) {
                let str = data.filter(function (item) {
                    return item && item.trim();
                });
                this.dataForm.queryCodes = str.join(',')
                this.textareaDialog = false;
                this.ifDisabled = true;
            } else {
                this.dataForm.queryCodes = ''
                this.textareaDialog = false
                this.ifDisabled = false;
            }
        },
        getInquire() {
            if(this.dataForm.queryCodes) {
                const { dataForm: { queryCodes } } = this
                // 是否多单号查询
                let ifBatch = queryCodes.includes(',') ? true : false
                if (ifBatch) {
                    this.dataList = []
                    let list = queryCodes.split(',')
                    for (let index = 0; index < list.length; index++) {
                        const item = list[index];
                        this.dataList.push({
                            order: item,
                            status: '未成功获取',
                            base64Str: ''
                        })
                        fhzmGet(item).then((res) => {
                            if(res && res.code == 0) {
                                this.dataListLoading = false
                                this.$set(this.dataList[index], 'status', '已成功获取')
                                this.$set(this.dataList[index], 'base64Str', res.data.base64Str)
                            }
                        }).catch(err => {
                            this.dataList = []
                        })
                    }
                } else {
                    this.dataList = [
                        {
                            order: queryCodes,
                            status: '未成功获取',
                            base64Str: ''
                        }
                    ]
                    fhzmGet(queryCodes).then((res) => {
                        if(res && res.code == 0) {
                            this.dataListLoading = false
                            this.dataList = [
                                {
                                    order: queryCodes,
                                    status: '已成功获取',
                                    base64Str: res.data.base64Str
                                }
                            ]
                        }
                    }).catch(err => {
                        this.dataListLoading = false
                        this.dataList = []
                    })
                }
            } else {
                this.dataList = []
                Message.error({ message: this.getLanguageText(this.languageType, '请输入单号')})
            }
        },
        // 列表选择
        selectionChange(val) {
            this.dataListSelections = val
        },
        // 只有成功的才能勾选
        checkSelectable(row) {
            return row.status == '已成功获取'
        },
        // 下载 
        downloadLabe(data, type) {
            if(type == 1) {
                let dataurl = this.dataURLtoBlob(data.base64Str)
                let url = window.URL.createObjectURL(dataurl)
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.target = "_blank"
                link.setAttribute('download', data.order)
                document.body.appendChild(link)
                link.click();
            } else {
                const zip = new JSZip();
                let urlList = []
                this.dataListSelections.map(item => {
                    let dataurl = this.dataURLtoBlob(item.base64Str)
                    urlList.push({ url: dataurl, name: item.order})
                })
                urlList.map((item, i) => {
                    zip.file(item.name+'.pdf', item.url, { binary: true });
                })
                zip.generateAsync({type:"blob"}).then(function(content) {
                    saveAs(content, `发货证明.zip`);
                })
            }
        },// base64转文件流
        dataURLtoBlob (dataurl) {
            var bstr = atob(dataurl);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: 'application/pdf' });
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 980px) {
    .qa {
        width: 90vw !important;
        .label {
            font-size: 2.5vw !important;
        }
        .content {
            font-size: 2.5vw !important;
        }
    }
    .bt_r {
        font-size: 2.5vw;
    }
    .el-textarea {
        font-size: 2.5vw;
    }
}
.order {
    /deep/ .el-input-group__append, .el-input-group__prepend {
        padding: 0;
        width: 40px;
        text-align: center;
        cursor: pointer;
    }
    /deep/ .el-input-group__prepend{
        width: 105px;
    }
}
.qa {
    width: 1300px;
    padding: 20px 0 50px;
    margin: 0 auto;
    .tracking {
        text-align: center;
        .tracking_title {
            font-weight: 700;
            -webkit-background-clip: text;
            background-clip: text;
            color: #003a9b;
            font-size: 40px;
            margin-bottom: 20px;
        }
        .tracking_form {
            width: 70%;
            margin: 0 auto;
            .tracking_input {
                // height: 120px !important;
                /deep/ .el-textarea__inner {
                    // height: 120px !important;
                    font-size: 16px !important;
                }
            }
            .tracking_but {
                height: 100px;
                // display: flex;
                padding-left: 20px;
                flex-direction: column;
                justify-content: space-around;
                font-size: 18px;
                .tracking_but_item {
                    height: 45%;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    line-height: 50px;
                    cursor: pointer;
                }
            }
        }
    }
    .mr10 {
        margin-right: 10px;
    }
    .color_fff {
        color: #fff;
    }
    .bg_olor_003a9b {
        background-color: #003a9b;
    }
}
.phone {
    text-align: center;
    color: red;
}
.p10 {
    padding: 10px
}
.mr10 {
    margin-right: 10px;
}
.mr20 {
    margin-right: 20px;
}
.mb10 {
    margin-bottom: 10px;
}
</style>