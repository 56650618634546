<template>
    <!-- 单号快速导入 -->
    <el-dialog
        title="快速导入"
        :visible.sync="textareaDialog"
        width="500px"
        :before-close="quickCancel"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        @open="open">
        <el-input class="textarea" type="textarea" :rows="15" placeholder="" v-model="textarea"></el-input>
        <span slot="footer" class="dialog-footer">
            <div>
                <el-radio-group v-model="form.radio">
                    <el-radio :label="0">行</el-radio>
                    <el-radio :label="1">空格</el-radio>
                    <el-radio :label="2">分号</el-radio>
                    <el-radio :label="3">逗号</el-radio>
                    <el-radio :label="4">其他</el-radio>
                    <el-input class="w20" v-model="form.input" :disabled="form.radio != 4"></el-input>
                </el-radio-group>
          </div>
          <el-button @click="quickCancel()">取 消</el-button>
          <el-button type="primary" @click="confirm()">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        textareaDialog: {
            type: Boolean,
            default: false
        },
        textarea2: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            form: {
                radio: 0,
                input: ''
            },
            textarea: ''
        }
    },
    methods: {
        open() {
            this.textarea = this.textarea2
        },
        // 取消
        quickCancel() {
            this.$emit('quickCancel', false);
        },
        // 确定
        confirm() {
            if(this.textarea) {
                // 字符串转数组
                let label = '\n'
                if(this.form.radio == 0) {
                    label = '\n'
                } else if(this.form.radio == 1) {
                    label = ' '
                } else if(this.form.radio == 2) {
                    label = ';'
                } else if(this.form.radio == 3) {
                    label = '，'
                } else if(this.form.radio == 4) {
                    label = this.form.input
                }
                let arr = this.textarea.split(label)
                // 剔除空元素及空格
                for(var i = 0;i<arr.length;i++){
                    if(arr[i]==''||arr[i]==null||typeof(arr[i])==undefined){
                        arr.splice(i,1);
                        i=i-1;
                    } else {
                        arr[i] = arr[i].replace(/^\s+|\s+$/g,"")
                    }
                }
                // 限制单次最多查询条数
                let indexNum = 10000;
                if(arr.length > indexNum) {
                    this.$confirm(`单次最多查询[ ${indexNum} ]条数据,是否将其余数据复制至剪切板？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let newArr1 = arr.slice().splice(0, indexNum)
                        this.$emit('confirm', newArr1);
                        let newArr2 = arr.slice().splice(indexNum, arr.length).join('\r')
                        // this.copyPageUrl(newArr2)
                    })
                } else {
                    this.$emit('confirm', arr);
                }
            } else {
                this.$emit('confirm', []);
            }
        },
        // 复制内容至剪切板，需是https
        async copyPageUrl(str) {
            try {
                await navigator.clipboard.writeText(str);
            } catch (err) {
                this.$message.error('复制失败，请检查访问网址路径是否为https')
            }
        }
    }
}
</script>

<style scoped>
    .cp {
        cursor: pointer;
    }
    .el-textarea__inner {
        overflow: auto !important;
		resize: none;
    }
    .el-radio-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .el-radio {
        margin-right: 0;
    }
    .w20 {
        width: 20%;
    }
</style>